import request from '@/utils/request'


// 查询用户礼物背包列表
export function listUser(query) {
  return request({
    url: '/biz/userGift/list',
    method: 'get',
    params: query
  })
}

// 查询用户礼物背包分页
export function pageUser(query) {
  return request({
    url: '/biz/userGift/getUserGiftPage',
    method: 'get',
    params: query
  })
}

// 查询用户礼物背包详细
export function getUser(data) {
  return request({
    url: '/biz/userGift/detail',
    method: 'get',
    params: data
  })
}

// 新增用户礼物背包
export function addUser(data) {
  return request({
    url: '/biz/userGift/add',
    method: 'post',
    data: data
  })
}

// 修改用户礼物背包
export function updateUser(data) {
  return request({
    url: '/biz/userGift/edit',
    method: 'post',
    data: data
  })
}
export function addIntimate(data) {
  return request({
    url: '/biz/userGift/addIntimate',
    method: 'post',
    data: data
  })
}
// 删除用户礼物背包
export function delUser(data) {
  return request({
    url: '/biz/userGift/delete',
    method: 'post',
    data: data
  })
}
